import { BorderLink } from '@playbooks/interface/links';
import { TeamBadge } from 'components/team/team-badge';
import { TeamBadges } from 'components/team/team-badges';
import { TeamCardActions } from 'components/team/team-card-actions';
import { useAccount } from 'contexts';
import {
	ActionCard,
	DetailCard,
	DisplayCard,
	FeaturedCard,
	ListCard,
	PhotoCard,
	PillCard,
	PreviewCard,
	ProfileCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';

export const TeamActionCard = ({ team, loading, taskRunning, onClick, tailwind }) => {
	const account = useAccount();
	// Render
	return (
		<ActionCard
			icon='people-group'
			status={team.memberRole}
			photo={team.thumbnail}
			title={team.name}
			subtitle={team.tagline || '--'}
			loading={loading}
			tailwind={tailwind}>
			<TeamCardActions type='action' team={team} taskRunning={taskRunning} onClick={onClick} />
		</ActionCard>
	);
};

export const TeamDetailCard = ({ team, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='people-group'
			status={team.status}
			cover={team.cover}
			photo={team.thumbnail}
			title={team.name}
			subtitle={'@' + team.uuid}
			text={team.tagline || '--'}
			footer={team.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const TeamDisplayCard = ({ team, rootLink, children, tailwind }) => {
	// Render
	return (
		<DisplayCard
			icon='people-group'
			badges={<TeamBadges team={team} />}
			status={team.membership !== 'free' && team.membership}
			photo={team.thumbnail}
			title={team.name}
			subtitle={`@${team.uuid}`}
			text={team.tagline || '--'}
			href={`${rootLink}/teams/${team.uuid}`}
			tailwind={tailwind}>
			{children ? children : <TeamCardActions type='grid' team={team} rootLink={rootLink} />}
		</DisplayCard>
	);
};

export const TeamFeaturedCard = ({ team, loading, onClick, rootLink, tailwind }) => {
	// Render
	return (
		<FeaturedCard
			icon='people-group'
			photo={team.thumbnail}
			title={team.name}
			subtitle={team.tagline || '--'}
			href={`${rootLink}/teams/${team.uuid}`}
			tailwind={tailwind}
		/>
	);
};

export const TeamListCard = ({ team, loading, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='people-group'
			badges={<TeamBadges team={team} />}
			photo={team.thumbnail}
			title={team.name}
			subtitle={team.tagline || '--'}
			loading={loading}
			href={`${rootLink}/teams/${team.uuid}`}
			tailwind={tailwind}>
			<TeamCardActions type='list' team={team} rootLink={rootLink} />
		</ListCard>
	);
};

export const TeamPhotoCard = ({ team, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PhotoCard
			icon='people-group'
			photo={team.thumbnail}
			title={team.name}
			subtitle={team.tagline}
			href={`${rootLink}/teams/${team.uuid}`}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const TeamPillCard = ({ team, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PillCard
			icon='people-group'
			photo={team.thumbnail}
			title={team.name}
			subtitle={team.tagline}
			href={`${rootLink}/teams/${team.uuid}`}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const TeamPreviewCard = ({ team, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='people-group'
			status={team.status}
			photo={team.thumbnail}
			title={team.name}
			subtitle={team.tagline || '--'}
			loading={loading}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/teams/${team.uuid}`} />
		</PreviewCard>
	);
};

export const TeamProfileCard = ({ team, rootLink, tailwind }) => {
	// Render
	return (
		<ProfileCard
			icon='people-group'
			badge={<TeamBadge type='card' team={team} />}
			photo={team.thumbnail}
			title={team.name}
			subtitle={'@' + team.uuid}
			href={`${rootLink}/teams/${team.uuid}`}
			tailwind={tailwind}>
			<TeamCardActions type='grid' team={team} rootLink={rootLink} />
		</ProfileCard>
	);
};

export const TeamSearchCard = ({ team, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='people-group'
			modelName='team'
			photo={team.thumbnail}
			title={team.name}
			subtitle={'@' + team.uuid}
			onClick={() => onClick(team)}
			tailwind={tailwind}
		/>
	);
};

export const TeamSelectCard = ({ team, selected = [], onClick, loading, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='people-group'
			title={team.name}
			subtitle={'@' + team.uuid}
			selected={selected.map(v => v.id).includes(team.id)}
			loading={loading}
			onClick={() => onClick(team)}
			tailwind={tailwind}
		/>
	);
};
