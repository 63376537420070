import { SearchPillCard, SearchPreviewCard, SearchSearchCard } from 'components/search/search-card';
import { ListWrapper } from 'molecules/lists';

const SearchList = ({
	type = 'list',
	icon = 'magnifying-glass',
	title = 'search results',
	modelName = 'recent',
	text = '',
	hits,
	tailwind,
	...listProps
}) => {
	const props = { type, icon, title, text, tailwind, ...listProps };

	// Item
	const renderItem = props => {
		switch (type) {
			case 'pill':
				return <SearchPillCard hit={props.item} modelName={modelName} {...props} />;

			case 'preview':
				return <SearchPreviewCard hit={props.item} modelName={modelName} {...props} />;

			case 'search':
				return <SearchSearchCard hit={props.item} {...props} />;
		}
	};

	// Render
	return <ListWrapper data={hits} renderItem={renderItem} {...props} />;
};

export { SearchList };
