import { useState } from 'react';

import { AvatarBadge } from '@playbooks/interface/avatars';
import { Badge } from '@playbooks/interface/badges';
import { Div } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { Tooltip } from '@playbooks/interface/tooltips';

const UserBadge = ({ type, user }) => {
	const [open, setOpen] = useState(false);

	// Computed
	const computed = {
		bgColor: user.isVerified ? 'bg-green-400 dark:bg-green-400' : 'bg-gray-500',
		status: user?.isVerified ? 'verified' : 'unverified',
		icon: user?.isVerified ? 'check' : 'question',
	};

	// Methods
	const onToggle = v => setOpen(v);

	// Render
	switch (type) {
		case 'avatar':
			return <UserAvatarBadge user={user} open={open} computed={computed} onToggle={onToggle} />;

		case 'card':
			return <UserCardBadge user={user} open={open} computed={computed} onToggle={onToggle} />;

		case 'hero':
			return <UserHeroBadge user={user} open={open} computed={computed} onToggle={onToggle} />;

		default:
			return <UserDefaultBadge user={user} computed={computed} />;
	}
};

const UserAvatarBadge = ({ user, open, computed, onToggle }) => {
	// Render
	return (
		<Div position='absolute' inset='-top-2 -right-2' zIndex='z-10'>
			<Tooltip open={open} html={computed.status} placement='right' onHover={onToggle}>
				<AvatarBadge bgColor={computed.bgColor}>
					<FarIcon icon={computed.icon} />
				</AvatarBadge>
			</Tooltip>
		</Div>
	);
};

const UserCardBadge = ({ user, open, computed, onToggle }) => {
	// Render
	return (
		<Div position='absolute' inset='top-0 right-0' zIndex='z-10'>
			<Tooltip open={open} html={computed.status} placement='right' onHover={onToggle}>
				<AvatarBadge bgColor={computed.bgColor}>
					<FarIcon icon={computed.icon} />
				</AvatarBadge>
			</Tooltip>
		</Div>
	);
};

const UserHeroBadge = ({ user, open, computed, onToggle }) => {
	// Render
	return (
		<Div position='absolute' inset='-top-2 -right-2' zIndex='z-10'>
			<Tooltip open={open} html={computed.status} placement='right' onHover={onToggle}>
				<AvatarBadge bgColor={computed.bgColor}>
					<FarIcon icon={computed.icon} />
				</AvatarBadge>
			</Tooltip>
		</Div>
	);
};

const UserDefaultBadge = ({ user, computed }) => {
	// Render
	return (
		<Badge size='xs' color='white' bgColor={computed.bgColor}>
			<FarIcon icon={computed.icon} />
		</Badge>
	);
};

export { UserBadge };
