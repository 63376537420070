import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';

const TeamBadges = ({ team, ...props }) => {
	return (
		<Fragment>
			{team.featured && (
				<Badge {...props}>
					<FadIcon icon='badge-check' />
				</Badge>
			)}
			{team.membership !== 'free' && (
				<Badge {...props}>
					<Span>{team.membership}</Span>
				</Badge>
			)}
		</Fragment>
	);
};

export { TeamBadges };
