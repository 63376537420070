import { useState } from 'react';

import { AvatarBadge } from '@playbooks/interface/avatars';
import { Badge } from '@playbooks/interface/badges';
import { Div } from '@playbooks/interface/html';
import { FarIcon } from '@playbooks/interface/icons';
import { Tooltip } from '@playbooks/interface/tooltips';

const TeamBadge = ({ type, team }) => {
	const [open, setOpen] = useState(false);

	// Computed
	const computed = {
		bgColor: team.isVerified ? 'bg-green-400 dark:bg-green-400' : 'bg-gray-500',
		status: team?.isVerified ? 'verified' : 'unverified',
		icon: team?.isVerified ? 'check' : 'question',
	};

	// Methods
	const onToggle = v => setOpen(v);

	// Render
	switch (type) {
		case 'avatar':
			return <TeamAvatarBadge team={team} open={open} computed={computed} onToggle={onToggle} />;

		case 'card':
			return <TeamCardBadge team={team} open={open} computed={computed} onToggle={onToggle} />;

		case 'hero':
			return <TeamHeroBadge team={team} open={open} computed={computed} onToggle={onToggle} />;

		default:
			return <TeamDefaultBadge team={team} computed={computed} />;
	}
};

const TeamAvatarBadge = ({ team, open, computed, onToggle }) => {
	// Render
	return (
		<Div position='absolute' inset='-top-2 -right-2' zIndex='z-10'>
			<Tooltip open={open} html={computed.status} placement='right' onHover={onToggle}>
				<AvatarBadge bgColor={computed.bgColor}>
					<FarIcon icon={computed.icon} />
				</AvatarBadge>
			</Tooltip>
		</Div>
	);
};

const TeamCardBadge = ({ team, open, computed, onToggle }) => {
	// Render
	return (
		<Div position='absolute' inset='top-0 right-0' zIndex='z-10'>
			<Tooltip open={open} html={computed.status} placement='right' onHover={onToggle}>
				<AvatarBadge bgColor={computed.bgColor}>
					<FarIcon icon={computed.icon} />
				</AvatarBadge>
			</Tooltip>
		</Div>
	);
};

const TeamHeroBadge = ({ team, open, computed, onToggle }) => {
	// Render
	return (
		<Div position='absolute' inset='-top-2 -right-2' zIndex='z-10'>
			<Tooltip open={open} html={computed.status} placement='right' onHover={onToggle}>
				<AvatarBadge bgColor={computed.bgColor}>
					<FarIcon icon={computed.icon} />
				</AvatarBadge>
			</Tooltip>
		</Div>
	);
};

const TeamDefaultBadge = ({ team, computed }) => {
	// Render
	return (
		<Badge size='xs' color='white' bgColor={computed.bgColor}>
			<FarIcon icon={computed.icon} />
		</Badge>
	);
};

export { TeamBadge };
