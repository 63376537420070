import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';

const UserBadges = ({ user, ...props }) => {
	return (
		<Fragment>
			{user.featured && (
				<Badge {...props}>
					<FadIcon icon='badge-check' />
				</Badge>
			)}
			{user.membership !== 'free' && (
				<Badge {...props}>
					<Span>{user.membership}</Span>
				</Badge>
			)}
		</Fragment>
	);
};

export { UserBadges };
