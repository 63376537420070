import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { toCurrency } from 'utils';

const BountyBadges = ({ bounty, ...props }) => {
	return (
		<Fragment>
			{bounty.featured && (
				<Badge {...props}>
					<FadIcon icon='badge-check' />
				</Badge>
			)}
			{bounty.meta?.netRevenue > 0 && (
				<Badge {...props}>
					<FadIcon icon='arrow-up-right' />
					<Span>{toCurrency(bounty.meta?.netRevenue, 0)}</Span>
				</Badge>
			)}
		</Fragment>
	);
};

export { BountyBadges };
