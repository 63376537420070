import { TabBtn } from '@playbooks/interface/buttons';
import { Div, Span } from '@playbooks/interface/html';
import { capitalize } from 'utils';

const SearchResultsSubnav = ({ tableName, tableNames = [], onSelect }) => {
	// Methods
	const isActive = value => {
		return tableName === value;
	};

	// Render
	return (
		<Div border='border-b' borderRadius='' overflow='overflow-hidden overflow-x-scroll' spacing=''>
			<Span display='flex-start' space='space-x-2'>
				<TabBtn size='p-3' active={isActive('')} onClick={() => onSelect('')}>
					All
				</TabBtn>
				{tableNames.map((tableName, i) => (
					<TabBtn key={i} size='p-3' active={isActive(tableName)} onClick={() => onSelect(tableName)}>
						{capitalize(tableName)}
					</TabBtn>
				))}
			</Span>
		</Div>
	);
};

export { SearchResultsSubnav };
