import { BorderLink } from '@playbooks/interface/links';
import { Section, SectionBody, SectionFooter } from '@playbooks/interface/sections';
import { SearchList } from 'components/search/search-list';

const SearchAllSection = ({ query, hits, loading, onClick }) => {
	// Render
	return (
		<Section border='' borderRadius='' spacing=''>
			<SectionBody size='md' spacing=''>
				<SearchList type='search' hits={hits} loading={loading} tailwind={{ grid: { gap: '' } }} onClick={onClick} />
			</SectionBody>
			<SectionFooter border=''>
				<BorderLink size='md' nextIcon='chevron-right' href={`/search?query=${query}`} className='w-full'>
					View All
				</BorderLink>
			</SectionFooter>
		</Section>
	);
};

export { SearchAllSection };
