import { Fragment, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { SearchAllSection } from 'components/search/search-all-section';
import { SearchRecentSection } from 'components/search/search-recent-section';
import { SearchTrendingSection } from 'components/search/search-trending-section';
import { useStorage, useStore } from 'contexts';
import { log } from 'utils';

const SearchResultsSection = ({ query, results = [], loading, tableName, tableNames, rootLink, admin }) => {
	const [hits, setHits] = useState([]);
	const router = useRouter();
	const store = useStore();
	const storage = useStorage();

	// Computed
	const recent = useMemo(() => {
		const queries = storage.getValue('search') || [];
		return queries
			.filter(v => (tableName ? tableName === v.modelName : true))
			.filter(v => (tableNames.length > 0 ? tableNames.includes(v.modelName) : true))
			.sort((a, b) => (a.date < b.date ? 1 : -1));
	}, [tableName, tableNames]);

	// Hooks
	useEffect(() => {
		const formattedHits = tableName ? results : results.map(v => v.data.slice(0, 3)).flat();
		setHits(formattedHits);
	}, [results]);

	useEffect(() => {
		if (hits.length > 0) log('hits: ', hits);
	}, [hits]);

	// Methods
	const saveQuery = (modelId, modelName, query, result) => {
		try {
			const formattedQuery = query.toLowerCase();
			storage.pushSearchValue('search', { modelId, modelName, query: formattedQuery, result, date: new Date() });
			store.saveRecord({ url: '/searches', data: { modelId, modelName, query: formattedQuery, result } });
		} catch (e) {
			console.error(e);
		}
	};

	const onClick = result => {
		switch (result.index) {
			case 'bounties':
				saveQuery(result.id, 'bounties', result.name, result);
				return router.push(`${rootLink}/bounties/${result.uuid}`);

			case 'collections':
				saveQuery(result.id, 'collections', result.name, result);
				return router.push(`${rootLink}/collections/${result.uuid}`);

			case 'frameworks':
				saveQuery(result.id, 'frameworks', result.name, result);
				return router.push(`${rootLink}/frameworks/${result.uuid}`);

			case 'languages':
				saveQuery(result.id, 'languages', result.name, result);
				return router.push(`${rootLink}/languages/${result.uuid}`);

			case 'teams':
				saveQuery(result.id, 'teams', result.name, result);
				return router.push(`${rootLink}/teams/${result.uuid}`);

			case 'repos':
				saveQuery(result.id, 'repos', result.name, result);
				return router.push(`${rootLink}/repos/${result.uuid}`);

			case 'tools':
				saveQuery(result.id, 'tools', result.name, result);
				return router.push(`${rootLink}/tools/${result.uuid}`);

			case 'topics':
				saveQuery(result.id, 'topics', result.name, result);
				return router.push(`${rootLink}/topics/${result.uuid}`);

			case 'users':
				saveQuery(result.id, 'users', result.name, result);
				if (admin) return router.push(`${rootLink}/users/${result.uuid}`);
				return router.push(`${rootLink}/${result.uuid}`);
		}
	};

	// Render
	return (
		<Fragment>
			{!tableName && query.length < 3 ? (
				<Fragment>
					{recent.length > 0 ? (
						<SearchRecentSection
							api={{ page: 0, pageSize: 6 }}
							tableName={tableName}
							tableNames={tableNames}
							onClick={onClick}
						/>
					) : (
						<SearchTrendingSection
							api={{ page: 0, pageSize: 6 }}
							tableName={tableName}
							tableNames={tableNames}
							onClick={onClick}
						/>
					)}
				</Fragment>
			) : (
				<SearchAllSection hits={hits} query={query} loading={loading} onClick={onClick} />
			)}
		</Fragment>
	);
};

export { SearchResultsSection };
